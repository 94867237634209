import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/verification.css';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../components/axio';

export default function EmailVerification() {
    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(false);
    const [canRequestAgain, setCanRequestAgain] = useState(true);
    const [timer, setTimer] = useState(0);
    const navigate = useNavigate();
    const { login } = useAuth();

    useEffect(() => {
        const userId = localStorage.getItem('userId');

        if (userId) {
            const checkEmailVerification = async () => {
                try {
                    const response = await axiosInstance.get(`/checkUserStatus/${userId}`);

                    if (response.status === 200) {
                        setIsVerified(true);
                        localStorage.setItem('verified', 'true');
                        login('user');
                        setLoading(false);
                        navigate('/dashboard');
                    }
                } catch (error) {
                    console.error('Failed to check email verification:', error);
                }
            };

            // Check email verification every 5 seconds
            const intervalId = setInterval(checkEmailVerification, 5000);

            return () => clearInterval(intervalId);
        } else {
            navigate('/login');
        }
    }, [login, navigate]);

    // Handle resend email request
    const handleResendEmail = async () => {
        const userId = localStorage.getItem('userId');
        if (!userId) return;

        try {
            setCanRequestAgain(false);
            setTimer(60); // Start cooldown timer (60 seconds)

            sendVerificationLink(userId);

            console.log('Verification email resent');
        } catch (error) {
            console.error('Error resending verification email:', error);
        }
    };

    const sendVerificationLink = async (userId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sendVerificationEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId })
            });
            if (!response.ok) {
                if (response.status === 404 || response.status === 401) {
                    console.log(response.status)
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            }
        } catch (error) {
            console.error('Failed to fetch:', error);
        }
    }


    // Countdown Timer Effect
    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setCanRequestAgain(true);
        }
    }, [timer]);

    return (
        <div className="verification-container d-flex flex-column align-items-center justify-content-center text-center">
            <h2 className="mb-3">Verify Your Email</h2>
            <p className="verification-message mb-4">
                We've sent a verification link to your email. Please click on the link to verify your account.
            </p>

            {loading && (
                <div className="loader-container mt-3">
                    <Circles
                        height="80"
                        width="80"
                        color="#ff8a00"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                    <p className="mt-3 waiting-text">Waiting for verification...</p>
                </div>
            )}

            {!isVerified && (
                <>
                    <p className="text-warning mt-3">
                        It seems your email is not yet verified. Please check your inbox.
                    </p>

                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleResendEmail}
                        disabled={!canRequestAgain}
                    >
                        {canRequestAgain ? 'Request Again' : `Request Again (${timer}s)`}
                    </button>
                </>
            )}
        </div>
    );
}
